/* eslint-disable max-lines */
import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Chatbot} from 'models/settings';
import checkResponse from 'utils/checkResponse';

import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

type GreetingData = {
	id?: number;
	title?: string;
	text?: string;
	lang: string;
};

export default class SettingsService {
	static getSettings = async (token: UUID) => {
		try {
			const apiUrl = apiPath.settings;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchSettings = async (token: UUID, settings: any) => {
		try {
			const apiUrl = apiPath.settings;

			const {data, status} = await axios.patch(apiUrl, settings, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getGlobalSettings = async (token: UUID) => {
		try {
			const apiUrl = apiPath.globalSettings;

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchGlobalSettings = async (token: UUID, settings: any) => {
		try {
			const apiUrl = apiPath.globalSettings;

			const {data, status} = await axios.patch(apiUrl, settings, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getThemes = async (token: UUID) => {
		try {
			const apiUrl = apiPath.themes;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getTheme = async (token: UUID, themeId: number) => {
		try {
			const apiUrl = apiPath.theme(themeId);
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getColors = async (token: UUID, themeId: number) => {
		try {
			const apiUrl = apiPath.colors(themeId);
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static setColors = async (token: UUID, themeId: number, colors: string) => {
		try {
			const apiUrl = apiPath.colors(themeId);
			const body = {
				colors,
			};
			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getFont = async (token: UUID) => {
		try {
			const apiUrl = apiPath.fonts;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static uploadFont = async (token: UUID, fonts: {name: string; file: File}[], name: string) => {
		try {
			const apiUrl = apiPath.fonts;
			const formData = new FormData();
			formData.append('name', name);

			fonts.forEach(el => {
				formData.append(el.name, el.file);
			});

			const {data, status} = await axios.post(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteFont = async (token: UUID) => {
		try {
			const {data, status} = await axios.delete(apiPath.fontsDelete, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getEmotions = async (token: UUID) => {
		try {
			const apiUrl = apiPath.getEmotions;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getAvatars = async (token: UUID) => {
		try {
			const apiUrl = apiPath.getAvatars;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getIcons = async (token: UUID, themeId: number) => {
		try {
			const apiUrl = apiPath.getIcons(themeId);
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addNamedPic = async (token: UUID, type: string, pic: File, payload?: string) => {
		try {
			const apiUrl = apiPath.addNamedPic(type);
			const formData = new FormData();
			payload ? formData.append(payload, pic) : formData.append('pic', pic);
			formData.append('name', pic.name);

			const {data, status} = await axios.post(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchNamedPic = async (
		token: UUID,
		type: string,
		id: number,
		pic: File,
		payload?: string,
		themeId?: number
	) => {
		try {
			const apiUrl = apiPath.editNamedPic(type, id);

			const formData = new FormData();
			payload ? formData.append(payload, pic) : formData.append('pic', pic);
			themeId && formData.append('themeId', `${themeId}`);

			const {data, status} = await axios.patch(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteNamedPic = async (token: UUID, type: string, id: number) => {
		try {
			const apiUrl = apiPath.editNamedPic(type, id);

			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchLoader = async (token: UUID, file: File) => {
		try {
			const apiUrl = apiPath.settings;
			const formData = new FormData();
			formData.append('loader', file);

			const {data, status} = await axios.patch(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static exportSettings = async (token: UUID) => {
		try {
			const apiUrl = apiPath.exportSettings;

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static importSettings = async (token: UUID, settings: any) => {
		try {
			const apiUrl = apiPath.importSettings;

			const {data, status} = await axios.post(apiUrl, settings, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response.data.message);
		}
	};

	static resetSettings = async (token: UUID) => {
		try {
			const apiUrl = apiPath.resetSettings;

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response.data.message);
		}
	};

	static initSettings = async (token: UUID) => {
		try {
			const apiUrl = apiPath.initSettings;

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response.data.message);
		}
	};

	static getGreeting = async (token: UUID) => {
		try {
			const apiUrl = apiPath.greeting;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static postGreeting = async (token: UUID, greeting: GreetingData) => {
		try {
			const apiUrl = apiPath.greeting;
			const {data, status} = await axios.post(apiUrl, greeting, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchGreeting = async (token: UUID, id: number, greeting: GreetingData) => {
		try {
			const apiUrl = apiPath.patchGreeting(id);
			const {data, status} = await axios.patch(apiUrl, greeting, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteGreeting = async (token: UUID, id: number) => {
		try {
			const apiUrl = apiPath.patchGreeting(id);
			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getChatbotSettings = async (token: UUID) => {
		try {
			const apiUrl = apiPath.chatbot;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getChatbotModels = async (token: UUID, apikey: string) => {
		try {
			const apiUrl = apiPath.chatGPTModels(apikey);
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchChatbotSettings = async (token: UUID, settings: Chatbot) => {
		try {
			const apiUrl = apiPath.chatbot;
			const formData = new FormData();
			// eslint-disable-next-line array-callback-return
			Object.entries(settings).map(([key, value]) => {
				key !== 'pic' && formData.append(key, `${value}`);
			});
			formData.append('pic', settings.pic);

			const {data, status} = await axios.patch(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getTolokaUsage = async (token: UUID) => {
		try {
			const apiUrl = apiPath.tolokaUsage;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getDefaultSettings = async (token: UUID) => {
		try {
			const apiUrl = apiPath.defaultSettings;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getProjects = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(apiPath.projects, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getApiKey = async (token: UUID) => {
		try {
			const apiUrl = apiPath.apiKey;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getProject = async (token: UUID) => {
		try {
			const apiUrl = apiPath.project;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateApiKey = async (token: UUID, projectId: number, apiKey: string) => {
		try {
			const apiUrl = `${apiPath.project}/${projectId}`;
			const {data, status} = await axios.patch(
				apiUrl,
				{apiKey},
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getRoomStatus = async (token: UUID) => {
		try {
			const apiUrl = apiPath.roomStatus;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static postRoomStatus = async (token: UUID, value: any) => {
		try {
			const apiUrl = apiPath.roomStatus;
			const {data, status} = await axios.post(apiUrl, value, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchRoomStatus = async (token: UUID, value: any) => {
		try {
			const apiUrl = `${apiPath.roomStatus}/${value.id}`;
			const {data, status} = await axios.patch(apiUrl, value, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteRoomStatus = async (token: UUID, id: number) => {
		try {
			const apiUrl = `${apiPath.roomStatus}/${id}`;
			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getRoomStatusDefault = async (token: UUID) => {
		try {
			const apiUrl = apiPath.roomStatusDefault;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
