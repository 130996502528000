/* eslint-disable max-lines */

import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import UserRole from 'models/enums/UserRole.enum';
import {IRoom} from 'models/room';

type filter = {
	key: string;
	value: string | null;
	prefix: string;
};

export default class RoomService {
	static getRooms = async (
		token: UUID,
		limit: number,
		offset: number,
		order: string,
		filters?: filter[]
	) => {
		try {
			let apiUrl;

			if (filters) {
				let filtersString = '';

				// eslint-disable-next-line array-callback-return
				filters.map(filter => {
					filtersString += `&${filter.key}=${filter.prefix}${filter.prefix ? '_' : ''}${
						filter.value
					}`;
				});
				apiUrl = apiPath.getRooms(limit, offset, order, filtersString);
			} else apiUrl = apiPath.getRooms(limit, offset, order);

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getRoom = async (externalRoomId: string, token: UUID) => {
		try {
			const apiUrl = apiPath.getRoom(encodeURIComponent(externalRoomId));
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getPreCreatedRooms = async (token: UUID) => {
		try {
			const apiUrl = apiPath.getPreCreatedRooms;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteMessages = async (externalRoomId: string, token: UUID) => {
		try {
			const apiUrl = apiPath.deleteMessages(externalRoomId);
			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteRoom = async (externalRoomId: string, token: UUID) => {
		try {
			const apiUrl = apiPath.deleteRoom(externalRoomId);
			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static createRoom = async (roomData: IRoom, token: UUID, pic?: Blob | null) => {
		try {
			const apiUrl = apiPath.createRoom;
			const formData = new FormData();
			// eslint-disable-next-line array-callback-return
			Object.entries(roomData).map(([key, value]) => {
				if (value) {
					formData.append(key, `${value}`);
				}
			});

			if (pic) {
				formData.append('pic', pic);
			}

			const {data, status} = await axios.post(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchRoom = async (roomData: IRoom, roomId: string, token: UUID, pic?: Blob | null) => {
		try {
			let objData: any = null;
			if (pic) {
				objData = new FormData();
				// eslint-disable-next-line array-callback-return
				Object.entries(roomData).map(([key, value]) => {
					if (value) {
						objData.append(key, `${value}`);
					}
				});
				if (pic) {
					objData.append('pic', pic);
				}
			} else objData = roomData;

			const apiUrl = apiPath.getRoom(encodeURIComponent(roomId));
			const {data, status} = await axios.patch(apiUrl, objData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getRoomMessages = async (body: {
		token: UUID;
		externalRoomId: string;
		limit?: number | string;
		type?: {
			name: string;
			id: number;
		};
		ignoreAutoposted?: number;
		hideImages?: boolean;
	}) => {
		try {
			let apiUrl = apiPath.getRoomMessages(body.externalRoomId);

			const messageType = body.hideImages
				? 'messageType=USER,BET'
				: 'messageType=USER,BET,PIC,GAMBLE';
			if (typeof body.type !== 'undefined') {
				apiUrl = `${apiUrl}?limit=${body.limit || 100}&${body.type.name}=${
					body.type.id
				}&${messageType}`;
			} else apiUrl = `${apiUrl}?limit=${body.limit}&${messageType}`;

			if (body.ignoreAutoposted) {
				apiUrl = `${apiUrl}&ignoreAutoposted=${Number(body.ignoreAutoposted)}`;
			}

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${body.token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getAroundMessages = async (body: {
		token: UUID;
		externalRoomId: string;
		messageId: number;
		limit?: number;
		ignoreAutoposted?: number;
		hideImages?: boolean;
	}) => {
		try {
			const messageType = body.hideImages
				? 'messageType=USER,BET'
				: 'messageType=USER,BET,PIC,GAMBLE';

			let apiUrl = `${apiPath.getRoomAroundMessages(
				body.externalRoomId,
				body.messageId,
				body.limit || 100
			)}&${messageType}`;

			if (body.ignoreAutoposted) {
				apiUrl = `${apiUrl}&ignoreAutoposted=${Number(body.ignoreAutoposted)}`;
			}

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${body.token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static setRoomStatus = async (token: UUID, externalRoomId: string, value: string) => {
		try {
			const apiUrl = apiPath.setRoomStatus;

			const body = {
				externalRoomId,
				value,
			};

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static toggleRoomSpeak = async (token: UUID, externalRoomId: string, value: boolean) => {
		try {
			const apiUrl = apiPath.toggleRoomSpeak;

			const body = {
				externalRoomId,
				value,
			};

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static setTalkerRole = async (
		token: UUID,
		externalRoomId: string,
		talkerId: number,
		role: UserRole
	) => {
		try {
			const apiUrl = apiPath.setTalkerRole;

			const body = {
				externalRoomId,
				talkerId,
				value: role,
			};

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static setRoleByUserId = async (
		token: UUID,
		externalRoomId: string,
		externalUserId: string,
		role: UserRole
	) => {
		try {
			const apiUrl = apiPath.setTalkerRole;

			const body = {
				externalRoomId,
				externalUserId,
				value: role,
			};

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(
				// eslint-disable-next-line no-nested-ternary
				error.response.data.message
					? error.response.data.message
					: error.message
					? error.message
					: null
			);
		}
	};

	static setUserAvatar = async (token: UUID, pic: File, userId: number) => {
		try {
			const apiUrl = apiPath.setUserAvatar;
			const formData = new FormData();
			formData.append('pic', pic);
			formData.append('userId', userId.toString());

			const {data, status} = await axios.post(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static setUserInvitedGuest = async (
		token: UUID,
		externalRoomId: string,
		userId: number,
		value: boolean
	) => {
		try {
			const apiUrl = apiPath.setUserInvitedGuest;

			const body = {
				externalRoomId,
				userId,
				value,
			};

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static toggleRoomSlowmode = async (token: UUID, externalRoomId: string, value: boolean) => {
		try {
			const apiUrl = apiPath.setSlowmode;

			const body = {
				externalRoomId,
				value,
			};

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static setRoomSlowmodeTimeout = async (token: UUID, externalRoomId: string, value: number) => {
		try {
			const apiUrl = apiPath.setSlowmodeDelay;

			const body = {
				externalRoomId,
				value,
			};

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getUserByUserId = async (
		token: UUID,
		value: {externalUserId: string; externalRoomId?: string}
	) => {
		try {
			const apiUrl = apiPath.getUserByUserId;

			const {data, status} = await axios.post(apiUrl, value, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static precreateTalker = async (
		token: UUID,
		talkerData: {externalRoomId: string; externalUserId: string; role?: UserRole; isModer?: boolean}
	) => {
		try {
			const apiUrl = apiPath.precreateTalker;

			const {data, status} = await axios.post(apiUrl, talkerData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getPinnedMessage = async (token: UUID, externalRoomId: string) => {
		try {
			const apiUrl = `${apiPath.pinMessage}/${externalRoomId}`;

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static pinMessage = async (token: UUID, externalRoomId: string, messageId: number | null) => {
		try {
			const apiUrl = apiPath.pinMessage;

			const {data, status} = await axios.post(
				apiUrl,
				{externalRoomId, messageId},
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static unpinMessage = async (token: UUID, externalRoomId: string, messageId: number | null) => {
		try {
			const apiUrl = apiPath.unpinMessage;

			const {data, status} = await axios.post(
				apiUrl,
				{externalRoomId, messageId},
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static uploadStreamVideo = async (token: UUID, externalRoomId: string, file: File) => {
		try {
			const apiUrl = apiPath.uploadStreamVideo(externalRoomId);
			const formData = new FormData();
			formData.append('video', file);
			const {data, status} = await axios.post(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getStreamKey = async (token: UUID, externalRoomId: string, userId: number) => {
		try {
			const apiUrl = apiPath.getStreamKey;
			const body = {
				externalRoomId,
				userId,
			};
			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
