/* eslint-disable max-lines */
type ApiPath = {
	login: string;
	getRooms: (limit: number, offset: number, order: string, filters?: string) => string;
	getRoom: (externalRoomId: string) => string;
	getRoomMessages: (externalRoomId: string) => string;
	getRoomAroundMessages: (externalRoomId: string, messageId: number, limit: number) => string;
	getAroundMessages: (messageId: number, limit: number) => string;
	getPreCreatedRooms: string;
	getBanned: (limit: number, offset: number, order: string) => string;
	getBannedHistory: (limit: number, offset: number, order: string) => string;
	getReports: (limit: number, offset: number, order: string) => string;
	getReportsCount: string;
	getArchivedReports: (limit: number, offset: number, order: string) => string;
	getModers: string;
	deleteMessages: (externalRoomId: string) => string;
	deleteRoom: (externalRoomId: string) => string;
	createRoom: string;
	toggleUserBan: string;
	toggleTalkerBan: string;
	patchUserBan: (banId: number) => string;
	patchTalkerBan: (banId: number) => string;
	toggleTalkerMute: string;
	setMessageVisibility: string;
	setMessageFlagged: string;
	solveReport: string;
	deleteMessage: (messageId: number) => string;
	toggleTalkerModer: string;
	getAdmins: string;
	deleteProjectUser: (adminId: number) => string;
	projectInfo: string;
	addAdmin: string;
	getAdmin: (adminId: number) => string;
	updateAdmin: string;
	changeUserPassword: string;
	updateProjectUser: (adminId: number) => string;
	getVersions: (type: string, lang?: string) => string;
	getWordList: (type: string, lang?: string) => string;
	getVersionList: (id: string, lang?: string) => string;
	updateWordList: (type: string, lang?: string) => string;
	getAutocorrect: string;
	createAutocorrect: string;
	deleteAutocorrect: (itemId: number) => string;
	updateAutocorrect: (itemId: number) => string;
	getMessages: string;
	getEditedMessage: (messageId: number) => string;
	getUserMessages: (userId: number) => string;
	setUserVisibility: string;
	editMesage: (messageId: number) => string;
	setRoomStatus: string;
	toggleRoomSpeak: string;
	getSelf: string;
	setTalkerRole: string;
	setUserAvatar: string;
	getHighlights: string;
	getHighlightPics: string;
	getOffers: string;
	offer: (id: number) => string;
	sticker: string;
	stickers: string;
	getSticker: (id: number) => string;
	getHighlight: (highlightId: number) => string;
	agoraStartRecord: (roomID: string) => string;
	agoraStopRecord: (roomID: string) => string;
	agoraStatusRecord: (roomID: string) => string;
	encryptChatUserId: (id: string) => string;
	setUserInvitedGuest: string;
	chatRules: string;
	updateRules: (id: number) => string;
	getAutoBanned: (linit: number, offset: number, order: string) => string;
	getPolls: (limit: number, offset: number, order: string, filters?: string) => string;
	getPoll: (pollId: number) => string;
	setPoll: string;
	uploadPic: string;
	deletePoll: (pollId: number) => string;
	endPoll: (pollId: number) => string;
	patchPoll: (id: number) => string;
	checkPoll: string;
	getFeedback: (limit: number, offset: number, order: string, filters?: string) => string;
	setSlowmode: string;
	setSlowmodeDelay: string;
	settings: string;
	themes: string;
	theme: (themeId: number) => string;
	exportSettings: string;
	importSettings: string;
	resetSettings: string;
	initSettings: string;
	fonts: string;
	fontsDelete: string;
	colors: (themeId: number) => string;
	getEmotions: string;
	getAvatars: string;
	getIcons: (themeId: number) => string;
	addNamedPic: (type: string) => string;
	editNamedPic: (type: string, id: number) => string;
	greeting: string;
	patchGreeting: (id: number) => string;
	chatbot: string;
	ranking: string;
	apiKey: string;
	getFlaggedMessage: (limit: number, offset: number, order: string, filters?: string) => string;
	getNewFlaggedMessagesCount: string;
	resolveFlaggedMessage: string;
	updateFlaggedMessage: (id: number) => string;
	tolokaUsage: string;
	getUserByUserId: string;
	precreateTalker: string;
	exportDump: string;
	exportStatus: string;
	exportUserActivity: string;
	exportMessage: string;
	createProject: string;
	projectSettings: string;
	projects: string;
	deleteProject: (apikey: string) => string;
	welcome: string;
	getWelcome: (lang: string) => string;
	globalSettings: string;
	badge: string;
	analytics: (project: string) => string;
	reloadAnalytics: string;
	defaultSettings: string;
	bearer: string;
	deleteBearer: (id: number) => string;
	checkWebhooksUrl: string;
	prompts: string;
	promptHistory: string;
	deletePromptHistory: (id: number) => string;
	prompt: (id: number) => string;
	endPrompt: string;
	openaiModels: (key: string) => string;
	openaiApiKey: string;
	promptDraft: string;
	autopostingSettings: string;
	autopostingMessageDefault: string;
	deleteAutopostingMessagesDefault: string;
	autopostingMessage: string;
	autopostingMessageMultiple: string;
	deleteAutopostingMessages: string;
	deleteAutopostingMessage: (id: number) => string;
	deleteAutopostingMessageDefault: (id: number) => string;
	deleteMessageLang: (lang: string) => string;
	deleteMessageLangDefault: (lang: string) => string;
	chatGPTModels: (apikey: string) => string;
	images: string;
	roomStatus: string;
	roomStatusDefault: string;
	pinMessage: string;
	unpinMessage: string;
	reactions: string;
	changelog: string;
	changelogCountUnviewed: string;
	changelogViewedId: (id: number) => string;
	changelogSubscribe: string;
	project: string;
	bot: string;
	uploadStreamVideo: (externalRoomId: string) => string;
	getStreamKey: string;
};

const apiPath: ApiPath = {
	login: `${process.env.REACT_APP_PROXY_URL}/auth/login`,
	getAdmins: `${process.env.REACT_APP_PROXY_URL}/project_user`,
	addAdmin: `${process.env.REACT_APP_PROXY_URL}/user`,
	getAdmin: userId => `${process.env.REACT_APP_PROXY_URL}/user/${userId}`,
	updateAdmin: `${process.env.REACT_APP_PROXY_URL}/user`,
	changeUserPassword: `${process.env.REACT_APP_PROXY_URL}/user/password`,
	updateProjectUser: adminId => `${process.env.REACT_APP_PROXY_URL}/project_user/${adminId}`,
	deleteProjectUser: adminId => `${process.env.REACT_APP_PROXY_URL}/project_user/${adminId}`,
	projectInfo: `${process.env.REACT_APP_PROXY_URL}/project`,

	getRooms: (limit, offset, order, filters) =>
		`/admin/room?limit=${limit}&offset=${offset}&order=${order}${filters || ''}`,
	getRoom: externalRoomId => `/admin/room/${externalRoomId}`,
	getRoomMessages: externalRoomId => `/admin/message/room/${externalRoomId}`,
	getRoomAroundMessages: (externalRoomId, messageId, limit) =>
		`/admin/message/room/${externalRoomId}/around/${messageId}?limit=${limit}`,
	getAroundMessages: (messageId, limit) => `/admin/message/around/${messageId}?limit=${limit}`,
	getPreCreatedRooms: `/admin/room/premade`,
	getBanned: (limit, offset, order) =>
		`/admin/ban/current?limit=${limit}&offset=${offset}&order=${order}`,
	getBannedHistory: (limit, offset, order) =>
		`/admin/ban/history?limit=${limit}&offset=${offset}&order=${order}`,
	getReports: (limit, offset, order) =>
		`/admin/report/new?limit=${limit}&offset=${offset}&order=${order}`,
	getReportsCount: `admin/report/new/count`,
	getArchivedReports: (limit, offset, order) =>
		`/admin/report/solved?limit=${limit}&offset=${offset}&order=${order}`,
	getModers: `/admin/talker/moder`,
	deleteMessages: externalRoomId => `/admin/message/room/${externalRoomId}`,
	deleteRoom: externalRoomId => `/admin/room/${externalRoomId}`,
	createRoom: `/admin/room`,
	toggleUserBan: `/admin/user/ban`,
	toggleTalkerBan: `/admin/talker/ban`,
	patchUserBan: banId => `/admin/user/ban/${banId}`,
	patchTalkerBan: banId => `/admin/talker/ban/${banId}`,
	toggleTalkerMute: `/admin/talker/mute`,
	setMessageVisibility: `/admin/message/visible`,
	setMessageFlagged: `/admin/message/flag`,
	solveReport: `/admin/report/solve`,
	toggleTalkerModer: `/admin/talker/moder`,
	deleteMessage: messageId => `/admin/message/${messageId}`,
	getMessages: `/admin/message`,
	getEditedMessage: messageId => `admin/message/edited/${messageId}`,
	getUserMessages: userId => `/admin/message/user/${userId}`,
	setUserVisibility: `/admin/message/user/visible`,
	editMesage: id => `/admin/message/${id}`,
	setRoomStatus: `/admin/room/status`,
	toggleRoomSpeak: `/admin/room/speak`,
	getSelf: `/admin/user/me`,
	setTalkerRole: `/admin/talker/role`,
	setUserAvatar: `/admin/user/pic`,
	getHighlights: `/admin/advertisement`,
	getHighlightPics: `/admin/advertisement/pic`,
	getOffers: `/admin/offer`,
	offer: offerId => `/admin/offer/${offerId}`,
	getHighlight: highlightId => `/admin/advertisement/${highlightId}`,
	agoraStartRecord: roomID => `/admin/agora/start/${roomID}`,
	agoraStopRecord: roomID => `/admin/agora/stop/${roomID}`,
	agoraStatusRecord: roomID => `/admin/agora/status/${roomID}`,
	encryptChatUserId: id => `/admin/user/encrypt/${id}`,
	setUserInvitedGuest: `/admin/user/invitedGuest`,
	getAutoBanned: (limit, offset, order) =>
		`/admin/ban/auto?limit=${limit}&offset=${offset}&order=${order}`,
	getPolls: (limit, offset, order, filters) =>
		`/admin/poll?limit=${limit}&offset=${offset}&order=${order}${filters || ''}`,
	getPoll: pollId => `/admin/poll/${pollId}`,
	setPoll: `/admin/poll`,
	uploadPic: `/admin/pic`,
	deletePoll: pollId => `/admin/poll/${pollId}`,
	endPoll: pollId => `/admin/poll/${pollId}/end`,
	patchPoll: id => `/admin/poll/${id}`,
	checkPoll: `/admin/poll/check`,
	getFeedback: (limit, offset, order, filters) =>
		`/admin/feedback?limit=${limit}&offset=${offset}&order=${order}${filters || ''}`,
	setSlowmode: `/admin/room/slowmode`,
	setSlowmodeDelay: `admin/room/slowmode/delay`,
	chatbot: `/admin/chatbot`,
	ranking: `admin/ranking`,
	apiKey: `admin/apiKey`,
	getFlaggedMessage: (limit, offset, order, filters) =>
		`admin/flaggedMessage?limit=${limit}&offset=${offset}&order=${order}${filters || ''}`,
	getNewFlaggedMessagesCount: `admin/flaggedMessage/countNew`,
	resolveFlaggedMessage: `/admin/flaggedMessage/resolve`,
	updateFlaggedMessage: id => `/admin/flaggedMessage/${id}`,
	tolokaUsage: 'admin/toloka/usage',
	getUserByUserId: 'admin/user/find',
	precreateTalker: 'admin/talker/precreate',
	badge: '/admin/badge',
	bearer: `/admin/bearer`,
	deleteBearer: (id: number) => `/admin/bearer/${id}`,
	checkWebhooksUrl: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings/webhook/check`,
	images: `admin/message/pic`,

	getVersions: (type, lang) =>
		lang
			? `${process.env.REACT_APP_ANTIMAT_URL}/admin/${type}/version?lang=${lang}`
			: `${process.env.REACT_APP_ANTIMAT_URL}/admin/${type}/version`,
	getWordList: (type, lang) =>
		lang
			? `${process.env.REACT_APP_ANTIMAT_URL}/admin/${type}?lang=${lang}`
			: `${process.env.REACT_APP_ANTIMAT_URL}/admin/${type}`,
	getVersionList: (id, lang) =>
		lang
			? `${process.env.REACT_APP_ANTIMAT_URL}/admin/wordlist/${id}?lang=${lang}`
			: `${process.env.REACT_APP_ANTIMAT_URL}/admin/wordlist/${id}`,
	updateWordList: (type, lang) =>
		lang
			? `${process.env.REACT_APP_ANTIMAT_URL}/admin/${type}?lang=${lang}`
			: `${process.env.REACT_APP_ANTIMAT_URL}/admin/${type}`,
	getAutocorrect: `${process.env.REACT_APP_ANTIMAT_URL}/admin/autocorrect`,
	createAutocorrect: `${process.env.REACT_APP_ANTIMAT_URL}/admin/autocorrect`,
	deleteAutocorrect: itemId => `${process.env.REACT_APP_ANTIMAT_URL}/admin/autocorrect/${itemId}`,
	updateAutocorrect: itemId => `${process.env.REACT_APP_ANTIMAT_URL}/admin/autocorrect/${itemId}`,

	chatRules: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/rules`,
	updateRules: id => `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/rules/${id}`,
	sticker: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/sticker`,
	stickers: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/sticker/pack`,
	getSticker: (id: number) => `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/sticker/${id}`,
	settings: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings`,
	themes: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings/theme`,
	theme: (themeId: number) =>
		`${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings/theme/${themeId}`,
	fonts: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/font`,
	fontsDelete: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/font`,
	colors: (themeId: number) =>
		`${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings/colors/${themeId}`,
	exportSettings: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings/export`,
	importSettings: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings/import`,
	resetSettings: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings/reset`,
	initSettings: `${process.env.REACT_APP_SETTINGS_SERVICE}admin/settings/init`,
	getEmotions: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings/emotion`,
	getAvatars: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings/avatar`,
	getIcons: themeId =>
		`${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings/namedPic/${themeId}/icon`,
	addNamedPic: type =>
		`${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings/namedPic/${type.toLocaleLowerCase()}`,
	editNamedPic: (type, id) =>
		`${
			process.env.REACT_APP_SETTINGS_SERVICE
		}/admin/settings/namedPic/${type.toLocaleLowerCase()}/${id}`,
	greeting: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/greeting`,
	patchGreeting: id => `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/greeting/${id}`,
	defaultSettings: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings/default`,
	projects: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings/projects`,
	welcome: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/welcome`,
	getWelcome: lang => `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/welcome/${lang}`,
	globalSettings: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/settings/global`,

	exportDump: `${process.env.REACT_APP_EXPORTER_URL}/dump`,
	exportStatus: `${process.env.REACT_APP_EXPORTER_URL}/status`,
	exportUserActivity: `${process.env.REACT_APP_EXPORTER_URL}/user_activity`,
	exportMessage: `${process.env.REACT_APP_EXPORTER_URL}/message`,

	createProject: `${process.env.REACT_APP_PARTNERS_CREATOR_URL}/project`,
	projectSettings: `${process.env.REACT_APP_PARTNERS_CREATOR_URL}/settings`,
	deleteProject: apikey => `${process.env.REACT_APP_PARTNERS_CREATOR_URL}/project/${apikey}`,

	analytics: project => `${process.env.REACT_APP_ANALYTICS_URL}/stat/analytics/${project}`,
	reloadAnalytics: `${process.env.REACT_APP_ANALYTICS_URL}/stat/analytics/load`,
	prompts: `${process.env.REACT_APP_SEMANTIC_ANALYSIS_SERVICE}/prompt`,

	promptHistory: `${process.env.REACT_APP_SEMANTIC_ANALYSIS_SERVICE}/prompt/history`,
	deletePromptHistory: (id: number) =>
		`${process.env.REACT_APP_SEMANTIC_ANALYSIS_SERVICE}/prompt/history/${id}`,
	prompt: (id: number) => `${process.env.REACT_APP_SEMANTIC_ANALYSIS_SERVICE}/prompt/${id}`,
	endPrompt: `${process.env.REACT_APP_SEMANTIC_ANALYSIS_SERVICE}/prompt/end`,
	openaiModels: (key: string) =>
		`${process.env.REACT_APP_SEMANTIC_ANALYSIS_SERVICE}/openai/models/${key}`,
	openaiApiKey: `${process.env.REACT_APP_SEMANTIC_ANALYSIS_SERVICE}/prompt/key`,
	promptDraft: `${process.env.REACT_APP_SEMANTIC_ANALYSIS_SERVICE}/prompt/draft`,

	autopostingSettings: `${process.env.REACT_APP_AUTOPOSTING_SERVICE_URL}/settings`,
	autopostingMessageDefault: `${process.env.REACT_APP_AUTOPOSTING_SERVICE_URL}/message/default`,
	deleteAutopostingMessagesDefault: `${process.env.REACT_APP_AUTOPOSTING_SERVICE_URL}/message/default/all`,
	autopostingMessage: `${process.env.REACT_APP_AUTOPOSTING_SERVICE_URL}/message`,
	autopostingMessageMultiple: `${process.env.REACT_APP_AUTOPOSTING_SERVICE_URL}/message/multiple`,
	deleteAutopostingMessages: `${process.env.REACT_APP_AUTOPOSTING_SERVICE_URL}/message/all`,
	deleteAutopostingMessage: (id: number) =>
		`${process.env.REACT_APP_AUTOPOSTING_SERVICE_URL}/message/${id}`,
	deleteAutopostingMessageDefault: (id: number) =>
		`${process.env.REACT_APP_AUTOPOSTING_SERVICE_URL}/message/default/${id}`,
	deleteMessageLang: (lang: string) =>
		`${process.env.REACT_APP_AUTOPOSTING_SERVICE_URL}/message/lang/${lang}`,
	deleteMessageLangDefault: (lang: string) =>
		`${process.env.REACT_APP_AUTOPOSTING_SERVICE_URL}/message/default/lang/${lang}`,
	chatGPTModels: (apiKey: string) => `/admin/openai/models/${apiKey}`,
	roomStatus: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/roomStatus`,
	roomStatusDefault: `${process.env.REACT_APP_SETTINGS_SERVICE}/admin/roomStatus/default`,
	pinMessage: `/admin/message/pin`,
	unpinMessage: `/admin/message/unpin`,
	reactions: `/admin/reaction/message`,
	changelog: `${process.env.REACT_APP_CHANGELOG_SERVICE_URL}/admin/changelog`,
	changelogCountUnviewed: `${process.env.REACT_APP_CHANGELOG_SERVICE_URL}/admin/changelog/countUnviewed`,
	changelogViewedId: (id: number) =>
		`${process.env.REACT_APP_CHANGELOG_SERVICE_URL}/admin/changelog/view/${id}`,
	changelogSubscribe: `${process.env.REACT_APP_CHANGELOG_SERVICE_URL}/admin/changelog/subscribe`,
	project: `${process.env.REACT_APP_ENVIRONMENT_SERVICE_URL}/admin/project`,
	bot: `/admin/bot`,
	uploadStreamVideo: externalRoomId => `/admin/room/upload/${externalRoomId}`,
	getStreamKey: `/admin/stream/key`,
};

export {apiPath};
